<template>
  <svg
    width="22"
    height="20"
    viewBox="0 0 22 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M21.9563 15.5338C21.8908 15.0125 21.6986 14.5346 21.441 14.0827C19.1087 10.0597 16.772 6.04096 14.4398 2.0179C13.8021 0.927419 12.8718 0.245325 11.6139 0.0498199C9.95864 -0.202164 8.46493 0.510342 7.60452 1.99183C5.24604 6.02792 2.90066 10.0684 0.564016 14.1131C-0.353171 15.6989 -0.139161 17.4758 1.11433 18.8096C1.90049 19.6437 2.89193 20 4.03623 20C8.67457 19.9957 13.3086 20 17.9469 20C18.0605 20 18.174 20 18.2919 19.9957C19.8031 19.9392 21.2488 18.8661 21.751 17.4454C21.8471 17.176 21.8733 16.8893 22 16.6286V15.6033C21.9651 15.599 21.9607 15.5729 21.9563 15.5425V15.5338Z"
      fill="#FAD653"
    />
    <path
      d="M10.4383 5.35247C9.8617 5.67569 9.67826 6.2042 9.7263 6.83317C9.87044 8.65019 9.94906 10.4672 10.0539 12.2886C10.0888 12.852 10.4688 13.2495 11.0061 13.2495C11.5346 13.2495 11.9495 12.8433 11.967 12.2886C12.0194 10.4497 12.1767 8.61962 12.2684 6.74581C12.299 6.63662 12.2684 6.49685 12.2596 6.35271C12.2029 5.47477 11.207 4.92443 10.4383 5.34811V5.35247Z"
      fill="#475569"
    />
    <path
      d="M11.0147 14.11C10.3202 14.11 9.75241 14.6691 9.74805 15.3636C9.74805 16.0493 10.3202 16.6346 11.006 16.639C11.6743 16.6433 12.2552 16.0624 12.2552 15.3854C12.2552 14.6647 11.7136 14.11 11.0191 14.11H11.0147Z"
      fill="#475569"
    />
  </svg>
</template>
